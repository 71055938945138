import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const navbarStyle = {
  border: '1px solid #ddd', 
};

const Menu = () => {
  return (
    <Navbar style={navbarStyle} expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" className='ml-1' />
      <Navbar.Collapse id="basic-navbar-nav" className='ml-2'> 
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/FileGeneration">File Generation</Nav.Link>
          <Nav.Link as={Link} to="/FileTransformator" disabled>File Transformation</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Menu;