import React, { useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';

const FileTransformator = () => {
  const [xmlContent, setXmlContent] = useState('');
  const [xsltContent, setXsltContent] = useState('');
  const [result, setResult] = useState('');
  const [validationError, setValidationError] = useState('');

  const handleXmlChange = (newXmlContent) => {
    setXmlContent(newXmlContent);
    setValidationError('');
  };

  const handleXsltChange = (newXsltContent) => {
    setXsltContent(newXsltContent);
    setValidationError('');
  };

  const handleSubmit = () => {
    // XML Validation
    try {
      const parser = new DOMParser();
      parser.parseFromString(xmlContent, 'application/xml');
    } catch (error) {
      setValidationError('Invalid XML');
      return;
    }

    // XSLT Transformation
    try {
      const xmlDoc = new DOMParser().parseFromString(xmlContent, 'application/xml');
      const xsltDoc = new DOMParser().parseFromString(xsltContent, 'application/xml');
      const xsltProcessor = new XSLTProcessor();
      xsltProcessor.importStylesheet(xsltDoc);
      const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
      setResult(new XMLSerializer().serializeToString(resultDoc));
    } catch (error) {
      setValidationError('Error during XSLT transformation');
    }
  };

  const editorOptions = {
    minimap: { enabled: false },
    scrollBeyondLastLine: false,
    wordWrap: 'on',
  };

  const monokaiTheme = {
    base: 'vs-dark',
    inherit: true
  };

  return (
    <div>
      <div className="position-relative mx-4 pt-2 pb-4"></div>

      <div className="container d-flex justify-content-center">
        <div className="mt-4 mb-3 p-4 col-lg-10 col-md-12 col-sm-12">
          <h3 className="mb-4">File Transformator</h3>
          <Form>
            <Row>
              <Col md={12} lg={6}>
                <Form.Group>
                  <Form.Label>XML Editor</Form.Label>
                </Form.Group>
              </Col>
              <Col md={12} lg={6}>
                <Form.Group>
                  <Form.Label>XSLT Editor</Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
              <Form.Group className='mt-3 mb-1'>
                  <Button variant="primary" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
              </Col>
            </Row>
            <Row>
              <Col>
                {result && (
                  <div>
                    <h2>Result:</h2>
                    <pre>{result}</pre>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default FileTransformator;