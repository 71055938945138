import React from 'react';
import { Container } from 'react-bootstrap';

const FileGeneratorExplain = () => {

  return (
    <Container className="mt-4">
      <div>
        <p>
          Welcome to the ID Generation tool! The feature facilitates the generation of batches of unique IDs efficiently, tailored to specific requirements.
          The tool offers a range of parameters to customize the ID-generation process:
        </p>
        <ul>
          <li>Start and end ID: Range definition within which IDs are generated.</li>
          <li>ID prefix or suffix: Optional text to prepend or append to each generated ID.</li>
          <li>Separator: Option to include a separator at the end of each line for better organization.</li>
          <li>Step size: Interval setting between consecutive IDs, useful for specific increments.</li>
          <li>Column name: Specification of a column name for IDs generated for tabular data.</li>
          <li>File extension: Selection of the desired file format for downloading generated IDs.</li>
        </ul>
        <p>
          Additionally, when selecting the appropriate start and end IDs, the tool can generate lists of decreasing IDs, offering further flexibility.
          Utilize these features to streamline the ID generation process.
        </p>
      </div>
    </Container>
  );
};

export default FileGeneratorExplain;
