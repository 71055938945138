import React, { useState } from 'react';
import axios from 'axios';
import { Row, Col, Form, Button, Alert, ListGroup } from 'react-bootstrap';
import FileGeneratorExplain from './FileGeneratorExplain';

const FileGenerator = () => {
    const [formData, setFormData] = useState({
        startID: '',
        endID: '',
        prefixID: '',
        suffixID: '',
        stepSize: 1,
        columnName: '',
        separator: '',
        extension: '',
        fileName: ''
    });

    const [errors, setErrors] = useState({});

    const [serverResponse, setServerResponse] = useState({});

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        if (!!errors[name]) setErrors({
            ...errors,
            [name]: null
        })
        setServerResponse({});
    }

    const findFormErrors = () => {
        const newErrors = {};

        if (!formData.fileName || formData.fileName === '') newErrors.fileName = 'Filename cannot be empty';
        else if (formData.fileName.length > 30) newErrors.fileName = 'Filename can have a maximum length of 30 characters.';

        // StartID errors
        if (!formData.startID || formData.startID === '') newErrors.startID = 'Start ID cannot be empty.';
        else if (/[a-z]/i.test(formData.startID)) newErrors.startID = 'Start ID cannot contain any non-numeric characters.';

        // EndID errors
        if (!formData.endID || formData.endID === '') newErrors.endID = 'End ID cannot be empty.';
        else if (/[a-z]/i.test(formData.endID)) newErrors.endID = 'End ID cannot contain any non-numeric characters.';

        // Stepsize
        if (!formData.stepSize || formData.stepSize === '') newErrors.stepSize = 'Step size cannot be empty.';
        else if (formData.stepSize === '0') newErrors.stepSize = 'Step size cannot be equal to 0.';
        else if (parseInt(formData.endID) < parseInt(formData.startID) && parseInt(formData.stepSize) > 0) newErrors.stepSize = 'Step size must be negative for this start and end ID';
        else if (parseInt(formData.endID) > parseInt(formData.startID) && parseInt(formData.stepSize) < 0) newErrors.stepSize = 'Step size must be positive for this start and end ID';


        return newErrors;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const newErrors = findFormErrors();

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        }
        else {
            setErrors({});
            setServerResponse({ ...serverResponse, 'Loading': true });
            axios.post('/api/generate-csv', formData)
                .then(response => {
                    // Handle the response data
                    if (response.data && response.data.error) {
                        setServerResponse({ ...serverResponse, 'Loading': false, 'type': 'danger', 'msg': response.data.error })
                        return;
                    }
                    else {
                        setServerResponse({ ...serverResponse, 'Loading': false, 'type': 'success', 'msg': 'Successfully downloaded the ID-file!' });
                        const blob = new Blob([response.data], { type: 'text/csv' });
                        // Trigger the file download
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', formData.fileName + formData.extension);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
                .catch(error => {
                    // Hanlde any errors that occured during the request
                    setServerResponse({ ...serverResponse, 'Loading': false, 'type': 'danger', 'msg': 'Request error: ' + error.message })
                });
        }
    };

    return (
        <div>
            <div className="position-relative mx-4 pt-2 pb-4">
                {serverResponse.msg &&
                    <Alert variant={serverResponse.type} className="position-absolute w-100">{serverResponse.msg}</Alert>
                }
            </div>

            <div className="container d-flex justify-content-center">
                <div className="mt-4 mb-3 p-4 col-lg-10 col-md-12 col-sm-12">
                    <h3>File Generation</h3>
                    <hr />
                    <FileGeneratorExplain/>
                    <hr />
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Form.Group className='mb-1 mt-1'>
                                    <Form.Label htmlFor='fileName'>Filename:</Form.Label>
                                    <Form.Control type='text' id='fileName' name='fileName' value={formData.fileName} onChange={handleInputChange} isInvalid={!!errors.fileName} disabled={serverResponse.Loading} />
                                    <Form.Control.Feedback type='invalid'>{errors.fileName} </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-1 mt-1'>
                                    <Form.Label htmlFor='extension'>File extension:</Form.Label>
                                    <Form.Control as="select" id="extension" name='extension' value={formData.extension} onChange={handleInputChange} disabled={serverResponse.Loading}>
                                        <option value=".csv">.csv</option>
                                        <option value=".txt">.txt</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className='mb-1 mt-1'>
                                    <Form.Label htmlFor='startID'>Start ID:</Form.Label>
                                    <Form.Control type='text' id='startID' name='startID' value={formData.startID} onChange={handleInputChange} isInvalid={!!errors.startID} disabled={serverResponse.Loading} />
                                    <Form.Control.Feedback type='invalid'>{errors.startID} </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className='mb-1 mt-1'>
                                    <Form.Label htmlFor='endID'>End ID:</Form.Label>
                                    <Form.Control type='text' id='endID' name='endID' value={formData.endID} onChange={handleInputChange} isInvalid={!!errors.endID} disabled={serverResponse.Loading} />
                                    <Form.Control.Feedback type='invalid'>{errors.endID} </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className='mb-1 mt-1'>
                                    <Form.Label htmlFor='prefixID'>ID Prefix:</Form.Label>
                                    <Form.Control type='text' id='prefixID' name='prefixID' value={formData.prefixID} onChange={handleInputChange} disabled={serverResponse.Loading} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-1 mt-1'>
                                    <Form.Label htmlFor='suffixID'>ID Suffix:</Form.Label>
                                    <Form.Control type='text' id='suffixID' name='suffixID' value={formData.suffixID} onChange={handleInputChange} disabled={serverResponse.Loading} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className='mb-1 mt-1'>
                                    <Form.Label htmlFor='stepSize'>Step size:</Form.Label>
                                    <Form.Control type='number' id='stepSize' name='stepSize' value={formData.stepSize} onChange={handleInputChange} isInvalid={!!errors.stepSize} disabled={serverResponse.Loading} />
                                    <Form.Control.Feedback type='invalid'>{errors.stepSize} </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className='mb-1 mt-1'>
                                    <Form.Label htmlFor='separator'>Separator:</Form.Label>
                                    <Form.Control as="select" id="separator" name='separator' value={formData.separator} onChange={handleInputChange} disabled={serverResponse.Loading}>
                                        <option value="">None</option>
                                        <option value=",">Comma (,)</option>
                                        <option value=";">Semicolon (;)</option>
                                        <option value="|">Pipe (|)</option>
                                        <option value=" ">Whitespace</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row> 
                        <Row>
                            <Col>
                                <Form.Group className='mb-1 mt-1'>
                                    <Form.Label htmlFor='columnName'>Column name:</Form.Label>
                                    <Form.Control type='text' id='columnName' name='columnName' value={formData.columnName} onChange={handleInputChange} disabled={serverResponse.Loading} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mt-3 mb-1">
                                    {serverResponse.Loading ?
                                        <div>
                                            <Button className="btn-primary" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm pl-2" role="status" aria-hidden="true"></span>
                                                Loading...
                                            </Button>
                                            <Form.Text className="text-muted">
                                                This might take up to 5 minutes.
                                            </Form.Text>
                                        </div>
                                        :
                                        <Button type="submit" className="btn-primary">Generate</Button>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </div>
    );
}
export default FileGenerator;