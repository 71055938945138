import MainPage from './MainPage';
import '../styles/App.css';

function App() {
  return (
    <div className="App">
      <MainPage/>
    </div>
  );
}

export default App;
